import { Swiper, Pagination, EffectFade } from 'swiper';
import 'swiper/swiper-bundle.css';

Swiper.use([Pagination, EffectFade]);

jQuery(($) => {
  const cardSwitchSwpier = document.querySelectorAll('.m-card-switch__swiper');

  cardSwitchSwpier.forEach((sliderInstance) => {
    const pagination = $(sliderInstance).parent().parent().find('.m-card-switch__pagination')[0];
    const paginationItems = $(sliderInstance).find('.m-card-switch__item');

    new Swiper(sliderInstance, {
      effect: 'fade',
      fadeEffect: {
        crossFade: true,
      },
      autoHeight: true,
      allowTouchMove: false,
      pagination: {
        el: pagination,
        clickable: true,
        renderBullet: (index, className) => {
          const title = $(paginationItems[index]).data().title;
          return `<span class=${className} data-aos="fade-right">${title}</span>`;
        },
      },
    });
  });
});
