jQuery(($) => {
  const navigationBarItems = $('.m-navigation-bar__item a');
  const navigationBar = $('.m-navigation-bar');
  const sections = $('section');
  const isStatic = $('.m-navigation-bar--static').length > 0;

  if (!isStatic) {
    setNavBarTransition();
  }

  if (navigationBar.length > 0 && !isStatic) {
    setNavBarTransition();
  }

  navigationBarItems.on('click', function (e) {
    if (!isStatic) {
      e.preventDefault();

      const sectionName = $(this).text();
      const sectionToMove = $(`[data-section='${sectionName}']`);

      setMenuItemActive(sectionName);
      let sectionOffsetTop = sectionToMove.offset().top - 79;

      if ($(window).width() < 992) {
        sectionOffsetTop = sectionToMove.offset().top - 40;
      }

      $('html, body').animate({
        scrollTop: sectionOffsetTop,
      }, 500);
    }
  });

  if (!isStatic) {
    // eslint-disable-next-line
    $(window).on('scroll', _.throttle(function () {
      const firstSectionBottomOffset = sections.first().outerHeight();
      const windowOffset = $(window).scrollTop();
      const isHeaderHide = $('.m-header').hasClass('hide');

      if (windowOffset <= firstSectionBottomOffset) {
        navigationBarItems.closest('section').removeClass('m-navigation-bar--height');
      }

      if (isHeaderHide) {
        navigationBarItems.closest('section').removeClass('m-navigation-bar--under-header');
      } else {
        navigationBarItems.closest('section').addClass('m-navigation-bar--under-header');
      }

      setMenuItem();
    }, 50));
  }

  function setNavBarTransition() {
    setTimeout(function () {
      navigationBar.css('transition', 'all 0.3s ease-in-out');
    }, 300);
  }

  function setMenuItem() {
    const windowScroll = $(window).scrollTop();
    const sections = $('section');
    let activeSection = '';

    sections.each(function () {
      const sectionName = $(this).data().section;
      if (sectionName) {
        const sectionOffset = $(this).offset().top;
        const windowOffset = windowScroll + 80;

        if (windowOffset >= sectionOffset) {
          activeSection = sectionName;
        }
      }
    });

    if (activeSection) {
      setMenuItemActive(activeSection);
    }
  }

  function setMenuItemActive(itemTitle) {
    const navbarItems = $('.m-navigation-bar__item');
    navbarItems.removeClass('active');

    navbarItems.each(function () {
      const itemName = $(this).text();
      const item = $(this);

      if (itemTitle.replace(/\s/g, '') === itemName.replace(/\s/g, '')) {
        item.addClass('active');
      }
    });
  }
});
