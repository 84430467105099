jQuery(($) => {
  const columnScrollExpandTextBtn = $('.m-column-scroll-3__item-expand');

  if (columnScrollExpandTextBtn.length) {
    columnScrollExpandTextBtn.on('click', function (e) {
      e.preventDefault();
      const display = $(this).parent().prev().css('display');
      const desc = $(this).parent().prev();

      if (display === 'none') {
        desc.slideDown();
        $(this).fadeOut();
        $(this).next().fadeIn();
      } else if (display === 'block') {
        desc.slideUp();
        $(this).fadeOut();
        $(this).prev().fadeIn();
      }
    });
  };
});
