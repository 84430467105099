jQuery(($) => {
  const expandText = $('.m-column-scroll-4__expand');

  if (expandText.length) {
    expandText.on('click', function (e) {
      e.preventDefault();
      const desc = $(this).parent().prev().find('.m-column-scroll-4__item-desc-expand');
      const display = desc.css('display');

      if (display === 'none') {
        desc.slideDown();
        $(this).fadeOut();
        $(this).next().fadeIn();
      } else if (display === 'inline') {
        desc.slideUp();
        $(this).fadeOut();
        $(this).prev().fadeIn();
      }
    });
  }
});
