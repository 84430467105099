import videojs from 'video.js';
import 'video.js/dist/video-js.css';

document.addEventListener('DOMContentLoaded', () => {
  const postBannerPlayers = document.querySelectorAll('.m-post-banner__video');
  const options = {
    autoplay: true,
    playsinline: true,
    loop: true,
    muted: true,
  };

  if (postBannerPlayers.length >= 1) {
    postBannerPlayers.forEach(function (videoPlayer) {
      if (videoPlayer) {
        videojs(videoPlayer, options, function onPlayerReady() {
          this.play();
          this.el_.style.visibility = 'visible';
          this.el_.style.opacity = '1';
        });
      };
    });
  }
});
