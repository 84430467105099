jQuery(function ($) {
  const animationLineSections = document.querySelectorAll('[data-line]');

  animationLineSections.forEach(section => {
    const isAltLine = $(section).data().linealt;
    const lineClass = isAltLine ? 'anim-line-inner anim-line-inner--alt' : 'anim-line-inner';
    const lineDiv = `<div class="anim-line"><div class="${lineClass}"></div></div>`;

    $(section).append(lineDiv);

    // eslint-disable-next-line
    $(window).on('scroll', _.throttle(() => {
      const sectionOffset = $(section).offset().top + $(section).outerHeight();
      // animate line after section bottom on reach 10% of window height since bottom
      const windowScroll = $(window).scrollTop() + $(window).height() * 0.9;

      setActiveLine(windowScroll, sectionOffset, section);
    }, 100));
  });

  function setActiveLine(scroll, sectionOffset, section) {
    if (scroll >= sectionOffset) {
      $(section).addClass('anim-line--focus');
    } else {
      $(section).removeClass('anim-line--focus');
    }
  }
});
