jQuery(($) => {
  const mobileMenuItems = $('.m-mobile-nav__nav .menu-item');
  const subMenuItem = $('.m-mobile-nav__sub-item');

  mobileMenuItems.on('click', function (e) {
    const subMenu = $(this).find('.m-mobile-nav__sub');

    if (subMenu.length > 0) {
      e.preventDefault();
      subMenu.slideToggle();
      $(this).toggleClass('active');
    }
  });

  subMenuItem.on('click', function (e) {
    e.stopPropagation();
  });
});
