import { Swiper, Navigation } from 'swiper';
import 'swiper/swiper-bundle.css';

Swiper.use([Navigation]);

window.addEventListener('DOMContentLoaded', () => {
  const jobSlider = document.querySelectorAll('.m-jobs__slider');

  jobSlider.forEach((slider) => {
    const prevButton = $(slider).find('.m-jobs__nav-prev')[0];
    const nextButton = $(slider).find('.m-jobs__nav-next')[0];

    new Swiper(slider, {
      allowTouchMove: true,
      slidesPerView: 1,
      spaceBetween: 30,
      threshold: 20,
      navigation: {
        nextEl: nextButton,
        prevEl: prevButton,
      },
      breakpoints: {
        575: {
          slidesPerView: 2,
          spaceBetween: 30,
        },
        767: {
          slidesPerView: 3,
          spaceBetween: 30,
        },
        991: {
          slidesPerView: 4,
          spaceBetween: 30,
        },
      },
    });
  });
});
