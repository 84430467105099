import Isotope from 'isotope-layout';

jQuery(($) => {
  const teamSection = $('.m-team');

  if (teamSection.length > 0) {
    const filterMembers = $('.m-team__filter-item a');

    const memberGrid = new Isotope('.m-team__list--grid', {
      itemSelector: '.m-team__item--grid',
      layoutMode: 'masonry',
      masonry: {
        columnWidth: '.m-team__item--grid',
        isFitWidth: true,
        gutter: 30,
      },
    });

    filterMembers.on('click', function (e) {
      e.preventDefault();
      filterMembers.parent().removeClass('m-team__filter-item--active');
      $(this).parent().addClass('m-team__filter-item--active');

      if ($(window).width() < 992) {
        $('html, body').animate({
          scrollTop: $(this).closest('.m-team__filter').next().offset().top - 85,
        }, 500);
      }

      const filterValue = $(this).data().filter;

      memberGrid.arrange({
        filter: filterValue,
      });
    });
  }
});
