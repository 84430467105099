import { Swiper, Navigation, Pagination, EffectFade, Autoplay } from 'swiper';
import 'swiper/swiper-bundle.css';

Swiper.use([Navigation, EffectFade, Pagination, Autoplay]);

const swiperDelay = 5000;

// eslint-disable-next-line
jQuery(($) => {
  const bannerSlider1 = document.querySelectorAll('.m-banner-slider-1__image-wrapper');
  const scrollDownButtons = document.querySelectorAll('.m-banner-slider-1__scroll');

  if (bannerSlider1?.length < 1 || scrollDownButtons?.length < 1) return;

  bannerSlider1.forEach((slider) => {
    const slides = slider.querySelectorAll('.swiper-slide');

    if (slides.length > 1) {
      const sliderInstance = new Swiper(slider, {
        effect: 'fade',
        loop: true,
        loopedSlides: 5,
        fadeEffect: {
          crossFade: true,
        },
        allowTouchMove: true,
        autoplay: {
          delay: swiperDelay,
        },
      });

      const pagination = $('.m-banner-slider-1__pagination-inner')[0];

      if (pagination) {
        const bannerSwiperPagNext = $(pagination).closest('.m-banner-slider-1__pagination').find('.m-banner-slider-1__pagination-next')[0];
        const bannerSwiperPagPrev = $(pagination).closest('.m-banner-slider-1__pagination').find('.m-banner-slider-1__pagination-prev')[0];

        const pag = new Swiper(pagination, {
          direction: 'vertical',
          loop: true,
          allowTouchMove: false,
          centeredSlides: true,
          slidesPerView: 5,
          loopedSlides: 5,
          autoplay: {
            delay: swiperDelay,
          },
          navigation: {
            nextEl: bannerSwiperPagNext,
            prevEl: bannerSwiperPagPrev,
          },
        });

        pag.on('slideChange', function (e) {
          changeSlider(e.realIndex, sliderInstance);
        });
        pag.on('click', function (e) {
          changeSlider(e.clickedIndex, pag);
        });
      }
    }
  });

  function changeSlider(index, swiper) {
    swiper.slideTo(index);
  }

  scrollDownButtons.forEach((button) => {
    $(button).on('click', function () {
      const currentSection = $(button).closest('section');
      const sectionBottomPosition = currentSection.offset().top + currentSection.outerHeight();

      $('html, body').animate(
        {
          scrollTop: sectionBottomPosition,
        },
        500,
      );
    });
  });
});
