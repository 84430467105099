import { Swiper, Navigation, Pagination, EffectFade, Autoplay } from 'swiper';
import 'swiper/swiper-bundle.css';

Swiper.use([Navigation, EffectFade, Pagination, Autoplay]);

// eslint-disable-next-line
jQuery(($) => {
  const projectsSlider = document.querySelectorAll('.m-projects-slider__slider');

  projectsSlider.forEach((slider) => {
    const navPrev = $(slider).find('.m-projects-slider__prev')[0];
    const navNext = $(slider).find('.m-projects-slider__next')[0];

    const sliderInstance = new Swiper(slider, {
      effect: 'fade',
      loop: true,
      fadeEffect: {
        crossFade: true,
      },
      allowTouchMove: false,
      autoHeight: true,
      autoplay: {
        delay: 5000,
      },
      navigation: {
        nextEl: navNext,
        prevEl: navPrev,
      },
      breakpoints: {
        991: {
          autoHeight: false,
        },
      },
    });

    const pagination = $('.m-projects-slider__pagination-inner')[0];

    if (pagination) {
      const bannerSwiperPagNext = $(pagination).closest('.m-projects-slider__pagination').find('.m-projects-slider__pagination-next')[0];
      const bannerSwiperPagPrev = $(pagination).closest('.m-projects-slider__pagination').find('.m-projects-slider__pagination-prev')[0];

      const pag = new Swiper(pagination, {
        direction: 'vertical',
        loop: true,
        allowTouchMove: false,
        centeredSlides: true,
        slidesPerView: 5,
        autoplay: {
          delay: 5000,
        },
        navigation: {
          nextEl: bannerSwiperPagNext,
          prevEl: bannerSwiperPagPrev,
        },
      });

      pag.on('slideChange', function (e) {
        changeSlider(e.realIndex, sliderInstance);
      });
    }
  });

  function changeSlider(index, swiper) {
    swiper.slideTo(index);
  }
});
