import 'owl.carousel';

jQuery(($) => {
  const columnTextSection = document.querySelectorAll('.m-column-text-3');

  columnTextSection.forEach(section => {
    const sectionOffset = $(section).offset().top;
    const animation = section.querySelector('.m-column-text-3__headline--icon');

    if (animation) {
      // eslint-disable-next-line
      $(window).on('scroll', _.throttle(function() {
        const windowScroll = $(window).scrollTop() + document.body.clientHeight / 2;

        animate(windowScroll, sectionOffset, section);
      }));
    }
  });

  function animate(scroll, sectionOffset, section) {
    if (scroll >= sectionOffset) {
      $(section).addClass('m-column-text-3--focus');
    } else {
      $(section).removeClass('m-column-text-3--focus');
    }
  }
});

window.addEventListener('DOMContentLoaded', () => {
  const logoSlider = document.querySelectorAll('.m-column-text-3__slider');

  logoSlider.forEach((slider) => {
    const sliderInstance = $(slider).find('.owl-carousel');

    // eslint-disable-next-line no-undef
    sliderInstance.owlCarousel({
      items: 2.5,
      loop: true,
      nav: false,
      autoplay: true,
      slideTransition: 'linear',
      autoplayTimeout: 3000,
      autoplaySpeed: 3000,
      touchDrag: false,
      mouseDrag: false,
      margin: 23,
      responsive: {
        768: {
          items: 3.5,
        },
        992: {
          items: 3,
          margin: 44,
        },
      },
    });
  });
});
