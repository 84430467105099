window.addEventListener('DOMContentLoaded', () => {
  const functionDescriptionSection = document.querySelectorAll('.m-function-description');
  const functionDescriptionSection2 = document.querySelectorAll('.m-function-description-2');

  enableColumnTextAnimations(functionDescriptionSection);
  enableColumnTextAnimations(functionDescriptionSection2);
});

function enableColumnTextAnimations(columnTextSection) {
  columnTextSection.forEach((section, index) => {
    const animation = section.querySelector('[data-headline]');
    const headlineSection = section.querySelector('.m-function-description__column-text');

    if (animation) {
      // eslint-disable-next-line
      $(window).on('scroll', _.throttle(function() {
        animate(headlineSection);
      }, 50));
    }
  });

  function animate(section) {
    if (isInViewport(section)) {
      $(section).addClass('animate');
    } else {
      $(section).removeClass('animate');
    }
  }
}

function isInViewport(element) {
  const rect = element.getBoundingClientRect();

  return (
    rect.top + (rect.height / 2) < (window.innerHeight || document.documentElement.clientHeight)
  );
}
