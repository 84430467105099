jQuery(($) => {
  const columnScroll2Section = $('.m-column-scroll-2');

  if (columnScroll2Section.length > 0) {
    const columnScroll2DescExpand = $('.m-column-scroll-2__item-expand');

    const sectionOffset = columnScroll2Section.offset().top;

    columnScroll2DescExpand.on('click', function (e) {
      e.preventDefault();
      const desc = $(this).parent().parent().find('.m-column-scroll-2__item-desc');
      const isActive = desc.hasClass('m-column-scroll-2__item-desc--expand');

      if (isActive) {
        desc.removeClass('m-column-scroll-2__item-desc--expand');
        $(this).fadeOut();
        $(this).prev().fadeIn();
      } else {
        desc.addClass('m-column-scroll-2__item-desc--expand');
        $(this).fadeOut();
        $(this).next().fadeIn();
      }
    });

    // eslint-disable-next-line
    $(window).on('scroll', _.throttle(function () {
      const windowScroll = $(window).scrollTop() + $(window).height() / 2;

      setActiveLine(windowScroll, sectionOffset, columnScroll2Section);
    }));
  }

  function setActiveLine(scroll, sectionOffset, section) {
    if (scroll >= sectionOffset) {
      section.addClass('m-column-scroll-2--active');
    } else {
      section.removeClass('m-column-scroll-2--active');
    }
  }
});
