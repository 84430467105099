/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */
import { Swiper, Navigation, Pagination, Autoplay, Scrollbar } from 'swiper';
import './scripts/publicPath';
import './scripts/fancyBox';
import './scripts/animLine';
import './scripts/animIcon';
import './scripts/clutch-widget';
import './scripts/iosZoomFix';
import './scripts/mobileNav';
import './scripts/animation';
import '@iconfu/svg-inject';
import 'simplebar'; // eslint-disable-line
import 'lodash';
import 'console-polyfill';
import 'swiper/swiper-bundle.css';
import { Fancybox } from '@fancyapps/ui';

import './main.scss';
import '@fancyapps/ui/dist/fancybox.css';

Swiper.use([Navigation, Pagination, Autoplay, Scrollbar]);

window.addEventListener('DOMContentLoaded', () => {
  const functionDescriptionSection = document.querySelectorAll('.m-function-description');

  // imageSlider selectors
  const imageSlider = document.querySelectorAll('.m-image-slider');

  enableSliders(imageSlider);
  enableSliders(functionDescriptionSection);

  // // columnText selectors
  const columnTextSection = document.querySelectorAll('.m-column-text');

  enableColumnTextAnimations(columnTextSection);

  Fancybox.bind('[data-fancybox="video"]', {
    Html: {
      video: {
        autoplay: false,
      },
    },
  });

  const URLParams = new URLSearchParams(window.location.search);
  const section = URLParams.get('section');

  if (section) {
    let sectionOffsetTop = $(`[data-section="${section}"]`).offset().top;

    if ($(window).width() < 992) {
      sectionOffsetTop = $(`[data-section="${section}"]`).offset().top - 40;
    }

    $('html, body').animate({
      scrollTop: sectionOffsetTop,
    }, 500);
  }
});

jQuery.event.special.touchstart = {
  setup: function(_, ns, handle) {
    this.addEventListener('touchstart', handle, { passive: !ns.includes('noPreventDefault') });
  },
};
jQuery.event.special.touchmove = {
  setup: function(_, ns, handle) {
    this.addEventListener('touchmove', handle, { passive: !ns.includes('noPreventDefault') });
  },
};
jQuery.event.special.wheel = {
  setup: function(_, ns, handle) {
    this.addEventListener('wheel', handle, { passive: true });
  },
};
jQuery.event.special.mousewheel = {
  setup: function(_, ns, handle) {
    this.addEventListener('mousewheel', handle, { passive: true });
  },
};

jQuery(function($) {
  // eslint-disable-next-line
  SVGInject(document.querySelectorAll('img.injectable'));

  const navBar = $('.m-navigation-bar--static');
  const header = $('.m-header');

  const activationLink = LoonarData.projectsLink; // eslint-disable-line
  const activationLink2 = LoonarData.blogLink; // eslint-disable-line

  if ((window.location.href !== activationLink && window.location.href !== activationLink2) && navBar.length > 0) {
    $([document.documentElement, document.body]).animate({
      scrollTop: navBar.offset().top - header.height(),
    }, 1);
  }
});

function importAll(r) {
  r.keys().forEach(r);
}

importAll(require.context('../Components/', true, /\/script\.js$/));

function enableSliders(sliders) {
  sliders.forEach((slider) => {
    const sliderInstance = $(slider).find('.m-image-slider__content')[0];
    const sliderScrollbar = $(slider).find('.m-image-slider__scrollbar')[0];

    new Swiper(sliderInstance, {
      allowTouchMove: true,
      slidesPerView: 1.2,
      spaceBetween: 15,
      threshold: 20,
      scrollbar: {
        el: sliderScrollbar,
        hide: false,
        draggable: true,
      },
      breakpoints: {
        577: {
          slidesPerView: 2.4,
          spaceBetween: 30,
        },
      },
    });
  });
}

function enableColumnTextAnimations(columnTextSection) {
  columnTextSection.forEach(section => {
    const animation = section.querySelector('.m-column-text__headline--icon');

    if (animation) {
      // eslint-disable-next-line
      $(window).on('scroll', _.throttle(function() {
        animate(section);
      }, 50));
    }
  });

  function animate(section) {
    if (isInViewport(section)) {
      $(section).addClass('m-column-text--focus');
    } else {
      $(section).removeClass('m-column-text--focus');
    }
  }
}

function isInViewport(element) {
  const rect = element.getBoundingClientRect();

  return (
    rect.top + (rect.height / 2) < (window.innerHeight || document.documentElement.clientHeight)
  );
}
