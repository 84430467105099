document.addEventListener('DOMContentLoaded', () => {
  const sections = Array.from(document.querySelectorAll('.m-evryplace-banner'));

  if (sections.length >= 1) {
    sections.forEach(section => {
      setTimeout(() => {
        section.classList.add('active');
      }, 900);
    });
  }
});
