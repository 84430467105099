import { Swiper, Navigation, Pagination, EffectFade, Autoplay } from 'swiper';
import 'swiper/swiper-bundle.css';

Swiper.use([Navigation, EffectFade, Pagination, Autoplay]);

jQuery(($) => {
  const bannerTextSliders = document.querySelectorAll('.m-banner-text__slider-inner');

  if (bannerTextSliders.length) {
    bannerTextSliders.forEach(slider => {
      const nextBtn = $(slider).parent().find('.m-banner-text__slider-next')[0];
      const prevBtn = $(slider).parent().find('.m-banner-text__slider-prev')[0];
      const activeLoop = $('.m-banner-text__slider-inner .swiper-slide').length > 1;

      new Swiper(slider, {
        slidesPerView: 1,
        loop: activeLoop,
        autoHeight: true,
        allowTouchMove: true,
        navigation: {
          nextEl: nextBtn,
          prevEl: prevBtn,
        },
        breakpoints: {
          991: {
            allowTouchMove: false,
          },
        },
        autoplay: {
          delay: 5000,
        },
      });
    });
  }
});
