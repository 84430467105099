jQuery(($) => {
  const contactForm = $('.m-contact');

  if (contactForm.length > 0) {
    const contactFormSubmit = contactForm.find('.m-contact__form input[type="submit"]');

    contactFormSubmit.on('click', function () {
      setDisabledButton(contactFormSubmit, true);
    });

    const contactFormElement = document.querySelector('.wpcf7');

    addListenerMulti(contactFormElement, 'wpcf7mailsent', function (e) {
      modalControl(true, contactFormElement);
      setDisabledButton(contactFormSubmit, false);
    });

    addListenerMulti(contactFormElement, 'wpcf7submit wpcf7invalid wpcf7spam wpcf7mailfailed', function (e) {
      setDisabledButton(contactFormSubmit, false);
    });

    const fileInput = $('span.your-file > input');
    const fileParent = $('.m-contact__form-item--file');

    const fileNameSpan = $('.m-contact__form-filename');
    const fileNameCross = $('.m-contact__form-cross');
    const currentTitle = fileParent.attr('title');

    fileNameSpan.text(currentTitle);

    fileNameCross.on('click', function() {
      fileInput.val('');
      fileNameSpan.text(currentTitle);
      $(this).css({ display: 'none' });
    });

    fileInput.on('change', function() {
      const fullPath = $(this).val();

      if (fullPath) {
        const fileName = getFileName(fullPath);
        fileNameSpan.text(fileName);
        fileNameCross.css({ display: 'block' });
      }
    });
  }

  function addListenerMulti(element, eventNames, listener) {
    const events = eventNames.split(' ');
    for (let i = 0, iLen = events.length; i < iLen; i++) {
      element.addEventListener(events[i], listener, false);
    }
  }

  function setDisabledButton(el, disable) {
    if (disable) {
      el.addClass('disabled');
    } else {
      el.removeClass('disabled');
    }
  }

  function modalControl(show, contactFormElement) {
    $(contactFormElement).find('.wpcf7-response-output').remove();
    if (show) {
      contactForm.next().addClass('active');
      $('html, body').addClass('blocked');
    } else {
      contactForm.next().removeClass('active');
      $('html, body').removeClass('blocked');
    }
  }

  function getFileName(fullPath) {
    const startIndex = (fullPath.indexOf('\\') >= 0 ? fullPath.lastIndexOf('\\') : fullPath.lastIndexOf('/'));
    let filename = fullPath.substring(startIndex);
    if (filename.indexOf('\\') === 0 || filename.indexOf('/') === 0) {
      filename = filename.substring(1);
    }
    return filename;
  }
});
