jQuery(($) => {
  const columnTextSection = document.querySelectorAll('.m-column-text');

  columnTextSection.forEach(section => {
    const sectionOffset = $(section).offset().top + ($(section).outerHeight() / 2);
    const animation = section.querySelector('.m-column-text__headline--icon');

    if (animation) {
      // eslint-disable-next-line
      $(window).on('scroll', _.throttle(function() {
        const windowScroll = $(window).scrollTop() + $(window).height();

        animate(windowScroll, sectionOffset, section);
      }, 50));
    }
  });

  function animate(scroll, sectionOffset, section) {
    if (scroll >= sectionOffset) {
      $(section).addClass('m-column-text--focus');
    } else {
      $(section).removeClass('m-column-text--focus');
    }
  }
});
