jQuery(($) => {
  const featureImageText = document.querySelectorAll('.m-text-w-image');

  featureImageText.forEach(section => {
    const sectionOffset = $(section).offset().top;

    // eslint-disable-next-line
    $(window).on('scroll', _.throttle(function() {
      const windowScroll = $(window).scrollTop() + $(window).height() * 0.1;

      setActiveLine(windowScroll, sectionOffset, section);
    }));
  });

  function setActiveLine(scroll, sectionOffset, section) {
    if (scroll >= sectionOffset) {
      $(section).addClass('m-text-w-image--focus');
    } else {
      $(section).removeClass('m-text-w-image--focus');
    }
  }
});
