import AOS from 'aos';
import 'aos/dist/aos.css';

jQuery(function ($) {
  AOS.init({
    duration: 600,
    easing: 'ease',
    once: true,
  });
});

window.addEventListener('DOMContentLoaded', AOS.refreshHard);
window.addEventListener('load', AOS.refreshHard);
