import 'owl.carousel';

window.addEventListener('DOMContentLoaded', () => {
  const productSlider = document.querySelectorAll('.m-product-slider');

  productSlider.forEach((slider) => {
    const sliderInstance = $(slider).find('.owl-carousel');
    const desktopItemsAmount = 6;
    const isAutoplayActive = $(sliderInstance).children().length > desktopItemsAmount;

    // eslint-disable-next-line no-undef
    sliderInstance.owlCarousel({
      items: 2,
      loop: true,
      nav: false,
      autoplay: true,
      slideTransition: 'linear',
      autoplayTimeout: 2000,
      autoplaySpeed: 2000,
      touchDrag: true,
      responsive: {
        540: {
          items: 3,
        },
        992: {
          items: desktopItemsAmount,
          mouseDrag: isAutoplayActive,
          touchDrag: isAutoplayActive,
          autoplay: isAutoplayActive,
        },
      },
    });
  });
});
