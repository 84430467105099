jQuery(($) => {
  const button = document.querySelector('#throwToForm');
  const featureList1 = document.querySelectorAll('.m-feature-list-1');

  featureList1.forEach(section => {
    const sectionOffset = $(section).offset().top;

    // eslint-disable-next-line
    $(window).on('scroll', _.throttle(function() {
      const windowScroll = $(window).scrollTop() + $(window).height() / 2;

      setActiveSection(windowScroll, sectionOffset, section);
    }));
  });

  if (button) {
    button.addEventListener('click', (e) => {
      e.preventDefault();

      $('html, body').animate({
        scrollTop: $('#contactForm').offset().top,
      }, 800);
    });
  }

  function setActiveSection(scroll, sectionOffset, section) {
    if (scroll >= sectionOffset) {
      $(section).addClass('m-feature-list-1--focus');
    } else {
      $(section).removeClass('m-feature-list-1--focus');
    }
  }
});
