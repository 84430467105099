import { Swiper } from 'swiper';

jQuery(($) => {
  const sections = $('.m-features-section');

  if (sections.length > 0) {
    sections.each(function() {
      const button = $(this).find('.m-features-section__read-more');
      const text = $(this).find('.m-features-section__wysiwyg-more');
      const lessText = button.data('opened');
      const moreText = button.data('closed');
      text.css({ display: 'none' });

      if (button.length > 0) {
        button[0].textContent = moreText;

        button.on('click', function() {
          if (!$(this).hasClass('active')) {
            text.slideDown();
            $(this).addClass('active');
            button[0].textContent = lessText;
          } else {
            text.slideUp();
            $(this).removeClass('active');
            button[0].textContent = moreText;
          }
        });
      }
    });
  }
});

document.addEventListener('DOMContentLoaded', () => {
  const sections = document.querySelectorAll('.m-features-section');

  sections.forEach(slider => {
    new Swiper('.m-features-section__gallery', {
      allowTouchMove: true,
      effect: 'fade',
      loop: true,
      slidesPerView: 1,
      spaceBetween: 40,
      threshold: 20,
      navigation: {
        nextEl: '.m-features-section__gallery-arrow--next',
        prevEl: '.m-features-section__gallery-arrow--prev',
      },
    });
  });
});
