import 'owl.carousel';

window.addEventListener('DOMContentLoaded', () => {
  const logoSlider = document.querySelectorAll('.m-logo-slider');

  logoSlider.forEach((slider) => {
    const sliderInstance = $(slider).find('.owl-carousel');

    // eslint-disable-next-line no-undef
    sliderInstance.owlCarousel({
      items: 4.5,
      loop: true,
      nav: false,
      autoplay: true,
      slideTransition: 'linear',
      autoplayTimeout: 3000,
      autoplaySpeed: 3000,
      touchDrag: false,
      mouseDrag: false,
      margin: 20,

      responsive: {
        792: {
          margin: 43,
        },
        992: {
          items: 5,
          margin: 63,
        },
      },
    });
  });
});
