jQuery(($) => {
  const contactModal = $('.m-contact-modal');

  contactModal.find('#contactFormModalBack').on('click', function(e) {
    e.preventDefault();
    closeModal();
    moveToTop();
  });

  contactModal.find('.m-contact-modal__close').on('click', function() {
    closeModal();
  });

  function closeModal() {
    contactModal.removeClass('active');
    $('html, body').removeClass('blocked');
  }

  function moveToTop() {
    $('html, body').animate({
      scrollTop: 0,
    }, 0);
  }
});
