jQuery(function ($) {
  // MOBILE MENU
  const mobileNavBtn = $('#headerBurger');
  const mobileNav = $('#mobileNav');

  mobileNavBtn.on('click', function (e) {
    e.preventDefault();
    window.scrollTo(0, 0);

    if ($(this).hasClass('active')) {
      $(this).removeClass('active');
      mobileNav.removeClass('active');
    } else {
      $(this).addClass('active');
      mobileNav.addClass('active');
    }

    blockWindow();
  });

  function blockWindow() {
    if ($('#searchPopup').hasClass('visible') || mobileNav.hasClass('active')) {
      $('html, body').addClass('blocked');
    } else {
      $('html, body').removeClass('blocked');
    }
  }
});
