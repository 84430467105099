import { Swiper, Navigation } from 'swiper';
import 'swiper/swiper-bundle.css';

Swiper.use([Navigation]);

jQuery(($) => {
  const cardSwitchSwpier = document.querySelectorAll('.m-card-swiper__swiper-inner');

  cardSwitchSwpier.forEach((sliderInstance) => {
    const prevSlide = $(sliderInstance).parent().find('.m-card-swiper__prev')[0];
    const nextSlide = $(sliderInstance).parent().find('.m-card-swiper__next')[0];

    const newSwiper = new Swiper(sliderInstance, {
      allowTouchMove: true,
      slidesPerView: 1,
      spaceBetween: 20,
      observer: true,
      observeParents: true,
      breakpoints: {
        575: {
          slidesPerView: 2,
          spaceBetween: 30,
        },
        991: {
          slidesPerView: 3,
          spaceBetween: 30,
        },
      },
      navigation: {
        nextEl: nextSlide,
        prevEl: prevSlide,
      },
    });

    $(window).on('resize', function () {
      newSwiper.updateSize();
    });
  });
});
