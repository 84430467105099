jQuery(($) => {
  const wpmlMenu = $('.m-header__side .m-header__language');
  const primaryMenuItems = $('.m-header__nav .menu-item');
  const subMenuItems = $('.m-header__sub-item');
  // const header = $('.m-header');

  primaryMenuItems.on('mouseenter', function () {
    primaryMenuItems.not($(this)).addClass('disabled');
  });

  primaryMenuItems.on('mouseleave', function () {
    primaryMenuItems.not($(this)).removeClass('disabled');
  });

  subMenuItems.on('mouseenter', function () {
    subMenuItems.not($(this)).addClass('disabled');
  });

  subMenuItems.on('mouseleave', function () {
    subMenuItems.not($(this)).removeClass('disabled');
  });

  wpmlMenu.on('mouseenter', function () {
    primaryMenuItems.not($(this)).addClass('disabled');
  });

  wpmlMenu.on('mouseleave', function () {
    primaryMenuItems.not($(this)).removeClass('disabled');
  });

  // header hide when scroll
  // let scrollCounter = 0;
  // eslint-disable-next-line
  // window.onscroll = _.throttle(function () {
  //   if (this.oldScroll > this.scrollY || this.scrollY < 10) {
  //     headerPosition(false);
  //     scrollCounter = 0;
  //   } else {
  //     scrollCounter++;
  //     if (scrollCounter > 15) {
  //       headerPosition(true);
  //     }
  //   }
  //   this.oldScroll = this.scrollY;
  // }, 10);

  // function headerPosition(hide) {
  //   if (hide) {
  //     header.addClass('hide');
  //   } else {
  //     header.removeClass('hide');
  //   }
  // }
});
