import '@fancyapps/fancybox';
import '@fancyapps/fancybox/dist/jquery.fancybox.css';

jQuery(function ($) {
  const fancyBoxSlider = $('.fancyLightbox');

  if (fancyBoxSlider.length > 0) {
    fancyBoxSlider.fancybox({
      loop: true,
      slideClass: 'custom-fancybox',
      baseClass: 'custom-fancybox',
    });
  }

  const wysiwygGalleries = document.querySelectorAll('.gallery-item');

  if (wysiwygGalleries) {
    wysiwygGalleries.forEach(i => {
      $(i).find('a')[0].dataset.fancybox = 'gallery';
    });
  }
});
